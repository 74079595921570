/**
 * external libs
 */
import React from 'react'
import ReactDOM from 'react-dom'
/**
 * styles
 */
import styles from './cookie.module.scss'

const Cookie: React.FC<{ setIsAcceptedCookie: React.Dispatch<React.SetStateAction<boolean>> }> = ({
    setIsAcceptedCookie,
}) => {
    const confirmCookie = () => {
        localStorage.setItem('acceptedCookie', '1')
        setIsAcceptedCookie(true)
    }

    return ReactDOM.createPortal(
        <div className={styles.cookie}>
            <div className={styles.cookie__textBlock}>
                <p className={styles.cookie__title}>Уведомление о Cookies</p>

                <p className={styles.cookie__text}>
                    Наш сайт использует файлы cookie.Продолжая пользоваться сайтом, вы соглашаетесьна использование нами
                    ваших файлов cookie{' '}
                    <a href="#" target="_blank">
                        Подробнее
                    </a>
                </p>
            </div>

            <button className={styles.cookie__button} onClick={confirmCookie}>
                Хорошо, спасибо
            </button>
        </div>,
        document.body as HTMLElement
    )
}

export default Cookie
