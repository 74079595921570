/**
 * external libs
 */
import React, { PropsWithChildren } from 'react'
/**
 * styles
 */
import styles from './h3.module.scss'

export const H3: React.FC<PropsWithChildren & { className?: string }> = ({ children, className }) => {
    return <h3 className={styles.h3 + ' ' + className}>{children}</h3>
}
