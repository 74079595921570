/**
 * external libs
*/
import React from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
/**
 * components
*/
import UIKits from "../pages/UIKits";
import Error404 from "../pages/error-pages/404";
import PublicLayout from "../layouts/PublicLayout";
/**
 * utils
*/
import { publicRoutes } from "./paths";

const Router: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                {
                    process.env?.NODE_ENV === "development" &&
                    <Route
                        element={ <UIKits /> }
                        path="/uikit"
                        key="/uikit"
                    />
                }

                <Route
                    element={ <Error404 /> }
                    path="/404"
                />

                <Route element={<PublicLayout />}>
                    {publicRoutes.reduce((accum: JSX.Element[], route) => {
                        const Component = route.component;

                        accum.push(
                            <Route
                                element={ <Component /> }
                                path={route.path}
                                key={route.path}
                            />
                        );

                        return accum;
                    }, [])}
                </Route>

                <Route path={"/*"} key={"404"} element={<Navigate to={"/404"} replace />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Router;