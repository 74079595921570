/**
 * external libs
*/
import React, { useEffect } from "react";
/**
 * types
*/
import { AlertType } from "./../../types";
/**
 * styles
*/
import styles from './alerts.module.scss';

type Props = {
    alert: AlertType;
    setAlerts: React.Dispatch<React.SetStateAction<AlertType[]>>;
};

const Alert: React.FC<Props> = ({ alert, setAlerts }) => {
    useEffect(() => {
        setTimeout(() => setAlerts(pre => pre.filter(item => item.id !== alert.id)), 3000);
    }, [setAlerts, alert.id]);

    return (
        <p className={`${styles.alerts__item} ${styles['alerts__item_' + alert.type]}`}>
            {alert.text}
        </p>
    );
};

export default Alert;