/**
 * external libs
 */
import React, { PropsWithChildren } from 'react'
/**
 * styles
 */
import styles from './h4.module.scss'

export const H4: React.FC<PropsWithChildren> = ({ children }) => {
    return <h4 className={styles.h4}>{children}</h4>
}
