import React from 'react'
/**
 * components
 */
import { Text, H2 } from '../../ui'
/**
 * styles
 */
import styles from './title.module.scss'

type Props = {
    label?: string
    title: string
}

export const Title: React.FC<Props> = ({ label, title }) => {
    return (
        <div className={styles.title}>
            <Text title={true}>{label}</Text>
            <H2>{title}</H2>
        </div>
    )
}
