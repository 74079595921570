/**
 * external libs
 */
import React from 'react'
import PhoneInput from 'react-phone-input-2'
/**
 * styles
 */
import styles from './input.module.scss'

type Props = {
    placeholder?: string
    isError?: boolean
    value: string
    change: (value: string) => void
    isOperatorField?: boolean
    info?: boolean
    required?: boolean
}

const PhoneInputComponent: React.FC<Props> = ({
    change,
    placeholder = '',
    isError = false,
    value = '',
    isOperatorField = false,
    info = false,
    required = false,
}) => {
    return (
        <div className={styles.input__wrapper}>
            <PhoneInput
                containerClass={`${styles.input} ${isError ? styles.input_error : ''} ${isOperatorField ? styles.little : ''} ${info ? styles.disabled : ''} ${required ? styles.input_required : ""}`}
                inputClass={`${styles.phoneInput} ${styles[`form-control`]} ${info ? styles.disabled : ''}`}
                buttonClass={`${styles.buttonClass} ${styles[`selected-flag`]}`}
                dropdownClass={styles.dropDown}
                country={'ru'}
                value={value}
                onChange={change}
                placeholder={placeholder}
                disabled={info}
            />

            {
                !!required &&
                <span className={styles.input__required}>*</span>
            }
        </div>
    )
}

export default PhoneInputComponent
