import * as yup from "yup";

export const formSchema = yup.object().shape({
    fio: yup
        .string()
        .typeError('Это поле обязательное.')
        .required("Это поле обязательное."),
    email: yup
        .string()
        .typeError('Это поле обязательное.')
        .required("Это поле обязательное.")
        .email("Неверный email"),
    phone: yup
        .string()
        .typeError('Это поле обязательное.')
        .required("Это поле обязательное."),
    file: yup
        .mixed()
        .nullable(),
});