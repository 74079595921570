/**
 * components
*/
import Home from "../pages/home/HomeLazy";
import About from "../pages/about/AboutLazy";
/**
 * types
*/
import { RouteType } from "../types";

export const publicRoutes: RouteType[] = [
    { path: "/", exact: true, component: Home, title: "Главная", description: "Главная" },
    { path: "/about", exact: true, component: About, title: "О нас", description: "О нас" },
];


