/**
 * external libs
 */
import React, { useContext, useState, useEffect, useMemo, PropsWithChildren } from 'react'
/**
 * components
 */
import Logo from '../logo/Logo'
import { Button, MenuLink } from '../ui'
import Portal from '../portal/Portal'
/**
 * context
 */
import { LayoutContext } from './../../layouts/PublicLayout'
/**
 * types
 */
import { LayoutContextType } from './../../types'
/**
 * styles
 */
import styles from './header.module.scss'

type Props = {
    conditionRef: React.RefObject<Element>
    mapRef: React.RefObject<Element>
    mediaRef: React.RefObject<Element>
    vacanciesRef: React.RefObject<Element>
    projectsRef: React.RefObject<Element>
}

const MOBILE_SIZE = 901

const EmptyWrapper: React.FC<PropsWithChildren & { open: boolean }> = ({ open, children }) => <>{children}</>

const Header: React.FC<Props> = ({ conditionRef, mediaRef, mapRef, vacanciesRef, projectsRef }) => {
    const { setOpenForm } = useContext<LayoutContextType>(LayoutContext)
    const [isMobile, setIsMobile] = useState(window.innerWidth < MOBILE_SIZE)
    const [open, setOpen] = useState(false)
    const [progress, setProgress] = useState(0)

    const Wrapper = useMemo(() => {
        return isMobile ? Portal : EmptyWrapper
    }, [isMobile])

    useEffect(() => {
        const checkIsMobile = () => {
            setIsMobile(window.innerWidth < MOBILE_SIZE)
            if (window.innerWidth >= MOBILE_SIZE) {
                setOpen(false)
            }
        }

        window.addEventListener('resize', checkIsMobile)

        return () => {
            window.removeEventListener('resize', checkIsMobile)
        }
    }, [setIsMobile, setOpen])

    useEffect(() => {
        const changeProgress = () => {
            const maxY = document.documentElement.scrollHeight - document.documentElement.clientHeight
            const scroll = document.body.getBoundingClientRect().top

            setProgress((-scroll * 100) / maxY)
        }

        window.addEventListener('scroll', changeProgress)
        window.addEventListener('resize', changeProgress)

        return () => {
            window.removeEventListener('scroll', changeProgress)
            window.removeEventListener('resize', changeProgress)
        }
    }, [setProgress])

    return (
        <header className={styles.header}>
            <div className={styles.header__progress}>
                <div className={styles.header__progressBar} style={{ width: `${progress}%` }} />
            </div>

            <div className="container">
                <div className={styles.header__container}>
                    <div className={styles.header__logo}>
                        <Logo />
                    </div>

                    <Wrapper open={open}>
                        <div className={styles.header__menu}>
                            <nav className={styles.header__nav} onClick={() => setOpen(false)}>
                                <MenuLink title="О нас" to="/about" />
                                <MenuLink title="Условия" to="/" anchor={conditionRef} anchorId="conditionRef" />
                                <MenuLink title="Медиа" to="/" anchor={mediaRef} anchorId="mediaRef" />
                                <MenuLink title="Карта" to="/" anchor={mapRef} anchorId="mapRef" />
                                <MenuLink title="Вакансии" to="/" anchor={vacanciesRef} anchorId="vacanciesRef" />
                                <MenuLink title="Наши проекты" to="/" anchor={projectsRef} anchorId="projectsRef" />
                            </nav>

                            <Button
                                handler={() => setOpenForm?.(true)}
                                label="Присоединиться"
                                subClasses={styles.header__join}
                            />
                        </div>
                    </Wrapper>

                    <div
                        className={`${styles.burger} ${open ? styles.active : ''}`}
                        onClick={() => setOpen((pre) => !pre)}>
                        <div className={styles.burger__line} />
                        <div className={styles.burger__line} />
                        <div className={styles.burger__line} />
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
