/**
 * external libs
 */
import React from 'react'
/**
 * styles
 */
import styles from './logo.module.scss'

const logo: React.FC = () => {
    return (
        <svg className={styles.logo} viewBox="0 0 80 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M56.1095 0.787138C54.135 1.21827 52.5163 1.57153 52.5149 1.57442C52.512 1.5773 52.3795 2.1108 52.2196 2.7611C51.6407 5.12293 50.5044 9.65771 50.1905 10.8646C50.1602 10.9828 50.1314 11.0708 50.1257 11.0592C50.1213 11.0477 49.9903 10.6065 49.8362 10.0787C49.4517 8.77238 49.1708 7.82938 48.8555 6.77679C48.4263 5.35219 47.6198 2.70631 47.6011 2.66593C47.591 2.6443 47.1662 2.7337 44.0396 3.41572L40.4897 4.18858L40.4853 15.9285L40.4825 27.6699H43.7573C45.559 27.6699 47.0365 27.6656 47.0394 27.6584C47.0437 27.6526 47.038 26.3145 47.0265 24.6852C46.9991 20.2946 46.9991 20.2586 47.0077 20.2701C47.0293 20.296 48.2261 24.3593 48.8857 26.6462C49.0326 27.158 49.1608 27.5978 49.168 27.6223L49.1838 27.6699H50.1098C50.6196 27.6699 51.0401 27.6642 51.0445 27.6569C51.0646 27.6238 52.1995 23.3399 52.7957 21.0372C52.9642 20.3912 53.1039 19.8591 53.1068 19.8563C53.1096 19.8534 53.1125 21.6096 53.1125 23.7595V27.6699H56.4465H59.7804V13.8349V-0.000139236L59.7415 0.00130272C59.7185 0.00274467 58.0853 0.35601 56.1095 0.787138Z" />
            <path d="M35.1683 5.34933C34.1444 5.57426 20.2643 8.5936 20.2556 8.5936C20.2513 8.5936 20.2484 12.8818 20.2484 18.1246V27.6555H29.8974H39.5463V18.2298V8.80412L39.3116 8.5662C39.182 8.43499 38.796 8.03847 38.4518 7.68809C37.6713 6.88927 35.9417 5.20225 35.8985 5.19504C35.8884 5.1936 35.56 5.26281 35.1683 5.34933ZM30.387 17.0287V23.0847H30.3453C30.2862 23.0847 29.7418 23.1106 29.6065 23.1207L29.4941 23.128V17.1253V11.1213L29.7649 11.0751C29.9132 11.0492 30.1019 11.0174 30.1854 11.003C30.2689 10.9872 30.3481 10.9756 30.3625 10.9742C30.3856 10.9727 30.387 11.2698 30.387 17.0287Z" />
            <path d="M14.8047 9.77738C12.3377 10.3152 10.3143 10.7564 10.3086 10.7564C10.3014 10.7564 10.297 13.4038 10.297 16.6394V22.5209L10.2509 22.5281C10.1933 22.5368 9.51935 22.58 9.44446 22.58H9.38974V16.7692C9.38974 13.5725 9.38542 10.9583 9.37966 10.9597C9.37246 10.9597 7.26265 11.4183 4.68767 11.9792L0.00720072 12.9986L0.00288029 20.335L0 27.6699H9.65617H19.3123V18.2327C19.3123 13.0418 19.308 8.79545 19.3022 8.79689C19.295 8.79833 17.2716 9.23956 14.8047 9.77738Z" />
            <path d="M60.7165 18.2904V27.6699L62.2186 27.667L63.7192 27.6627L63.7235 18.6047C63.7264 9.9879 63.725 9.54668 63.7019 9.53947C63.6616 9.52794 60.7482 8.9108 60.7309 8.9108C60.7208 8.9108 60.7165 11.8335 60.7165 18.2904Z" />
            <path d="M72.8569 19.578V27.6743L74.8472 27.6657C75.9417 27.6599 77.5489 27.6556 78.4187 27.6556H80V21.1122V14.5703L77.9233 14.1767C76.7813 13.9604 75.8423 13.7802 75.8351 13.7758C75.8293 13.7715 75.8236 13.3952 75.8236 12.941V12.1119L75.7919 12.1047C75.7732 12.1004 75.1323 11.9648 74.3676 11.8019C73.6029 11.6404 72.9505 11.502 72.9174 11.4948L72.8569 11.4803V19.578ZM76.4716 16.651L77.0117 16.7332L77.016 20.8325L77.0189 24.9304H76.9584C76.9238 24.9304 76.6632 24.9203 76.378 24.9088C76.0929 24.8972 75.8509 24.8871 75.8423 24.8871C75.8279 24.8871 75.8236 24.0465 75.8236 20.7301C75.8236 18.4433 75.8279 16.5688 75.8322 16.563C75.8365 16.5587 75.861 16.5587 75.8855 16.563C75.9114 16.5659 76.175 16.6063 76.4716 16.651Z" />
            <path d="M64.6913 13.2668C64.6913 14.1463 64.6956 14.8659 64.7028 14.8659C64.7086 14.8673 65.6432 15.0086 66.7795 15.1816L68.8461 15.4945L68.8504 15.9876C68.8519 16.2616 68.8475 16.4808 68.8418 16.4808C68.8259 16.4808 64.8036 15.9213 64.7388 15.9112L64.6913 15.9026V21.787V27.6699H68.2916H71.892V20.3523V13.0346L71.8603 13.0274C71.8416 13.0231 70.233 12.7189 68.2844 12.3497C66.3359 11.9806 64.7302 11.6764 64.7172 11.6735C64.6927 11.6677 64.6913 11.7513 64.6913 13.2668ZM68.2672 19.6977C68.5941 19.7294 68.8605 19.7568 68.8619 19.7582C68.8634 19.7597 68.8677 20.8569 68.8735 22.1965L68.8835 24.6304L68.7568 24.6217C68.6877 24.6174 68.4198 24.6059 68.162 24.5972C67.9042 24.5886 67.6925 24.5799 67.6911 24.5785C67.6882 24.577 67.658 20.3869 67.658 19.9731C67.658 19.7899 67.6623 19.6385 67.6666 19.6385C67.6709 19.6385 67.9417 19.6645 68.2672 19.6977Z" />
            <path d="M39.928 29.8849C39.2425 29.9267 38.76 30.1098 38.3928 30.4631C38.2488 30.6029 38.1465 30.7471 38.0371 30.9649C37.9089 31.2244 37.8614 31.4392 37.8614 31.7723C37.8614 32.3217 38.0227 32.747 38.3582 33.0859C38.7442 33.4752 39.2569 33.654 39.9842 33.654C40.3399 33.6554 40.5559 33.6252 40.8065 33.5444C40.9779 33.4882 41.2083 33.3642 41.3393 33.2604L41.4545 33.1666L41.4589 33.3599L41.4632 33.5531H41.8002H42.1386V32.6014V31.6498H41.1881H40.2376L40.2405 31.9699L40.2448 32.2914L40.818 32.2957C41.2616 32.2986 41.3897 32.3029 41.3897 32.3174C41.3897 32.326 41.3638 32.3794 41.3336 32.4356C41.2328 32.6173 41.0355 32.7831 40.8094 32.8768C40.0763 33.1811 39.1921 32.9518 38.9041 32.3822C38.7716 32.1198 38.7327 31.7219 38.8119 31.4118C38.8911 31.0961 39.0985 30.8437 39.4095 30.6866C39.6702 30.5554 40.0331 30.5078 40.3644 30.5611C40.6538 30.6087 40.8482 30.7039 41.0412 30.8942L41.1752 31.0269L41.6533 30.9605C41.9168 30.9245 42.1343 30.8928 42.1357 30.8913C42.1458 30.8841 42.0652 30.7471 41.9946 30.652C41.744 30.3189 41.2803 30.0464 40.7978 29.9498C40.5372 29.8979 40.1671 29.8705 39.928 29.8849Z" />
            <path d="M77.7894 29.8849C77.124 29.9253 76.6257 30.1099 76.2715 30.4458C76.1217 30.5871 76.0079 30.7457 75.9014 30.9649C75.7545 31.2663 75.7141 31.4768 75.7271 31.8661C75.7401 32.228 75.8135 32.5034 75.9676 32.7601C76.2167 33.1739 76.5941 33.445 77.101 33.5733C77.33 33.631 77.5446 33.654 77.8456 33.654C78.4807 33.6555 78.8825 33.5272 79.2699 33.2056L79.3231 33.1609V33.357V33.5531H79.6616H80V32.6015V31.6498H79.0495H78.099L78.1019 31.9699L78.1062 32.2914L78.6866 32.2958L79.267 32.2987L79.2079 32.4126C79.0366 32.7413 78.6434 32.959 78.135 33.0052C77.6828 33.0484 77.2176 32.8999 76.9455 32.6274C76.8086 32.4904 76.7208 32.3232 76.6632 32.0896C76.6257 31.9339 76.6286 31.595 76.6704 31.4263C76.7813 30.9779 77.1168 30.678 77.631 30.5655C77.6857 30.554 77.8095 30.5467 77.9694 30.5482C78.1926 30.5496 78.2373 30.554 78.3611 30.5871C78.5829 30.6462 78.7428 30.7371 78.9026 30.8942L79.0366 31.0269L79.5147 30.9606C79.7782 30.9245 79.9957 30.8928 79.9986 30.8914C80.0014 30.8885 79.9827 30.8495 79.9568 30.8034C79.7264 30.3939 79.2266 30.0652 78.6607 29.9498C78.4115 29.8993 78.0256 29.8705 77.7894 29.8849Z" />
            <path d="M11.9676 29.9064C11.3915 29.9727 10.8486 30.2323 10.5649 30.5769C10.0853 31.1609 10.0364 32.2019 10.4583 32.8349C10.8313 33.3915 11.5514 33.6929 12.4428 33.664C12.7842 33.6525 13.0088 33.6135 13.3372 33.5068L13.4754 33.4621L13.8196 33.6683C14.0097 33.7837 14.2085 33.9034 14.2632 33.938L14.3626 33.9985L14.557 33.6784C14.6635 33.5025 14.7528 33.3511 14.7572 33.3439C14.76 33.3353 14.6247 33.2574 14.4562 33.1723C14.2877 33.0858 14.1422 33.0094 14.1321 33.0022C14.1192 32.9921 14.1321 32.9661 14.1768 32.9041C14.3251 32.7037 14.4504 32.3908 14.495 32.1111C14.5267 31.9107 14.5267 31.5733 14.495 31.3829C14.4331 31.0152 14.2776 30.7009 14.0472 30.4731C13.739 30.1674 13.2608 29.9641 12.7136 29.905C12.5509 29.8877 12.1231 29.8891 11.9676 29.9064ZM12.5364 30.5409C12.9642 30.5928 13.3069 30.8278 13.4884 31.1955C13.5878 31.3988 13.6137 31.5141 13.6137 31.7795C13.6137 31.9741 13.6094 32.0202 13.5777 32.1414C13.4567 32.5941 13.1428 32.9012 12.6963 33.0022C12.5523 33.0339 12.1433 33.0339 11.9993 33.0007C11.5528 32.8984 11.249 32.6042 11.1237 32.1543C11.1021 32.0779 11.0963 32.0102 11.0977 31.7939C11.0977 31.5459 11.1006 31.5185 11.1366 31.4002C11.1827 31.2488 11.2288 31.1537 11.3123 31.0369C11.5773 30.6634 12.031 30.4789 12.5364 30.5409Z" />
            <path d="M34.0162 29.9063C33.5064 29.9626 33.1809 30.0981 32.9404 30.3562C32.7806 30.5278 32.7086 30.7023 32.6956 30.9517C32.674 31.3381 32.8353 31.6236 33.1838 31.814C33.3912 31.9293 33.6936 32.0043 34.3402 32.1038C34.9825 32.2018 35.1366 32.2451 35.2533 32.359C35.3051 32.4109 35.3311 32.5147 35.3138 32.607C35.2648 32.8723 34.9581 33.0079 34.4122 33.0079C34.0623 33.0079 33.842 32.9632 33.6058 32.8406C33.4733 32.7728 33.3249 32.6344 33.2673 32.5248C33.2472 32.4873 33.2241 32.4599 33.214 32.4628C33.2054 32.4671 33.0268 32.5133 32.818 32.5652C32.6092 32.6185 32.4364 32.6632 32.4335 32.6661C32.4306 32.669 32.4493 32.7094 32.4753 32.7555C32.6524 33.0713 32.9735 33.3554 33.3019 33.4894C33.8434 33.7101 34.7867 33.7274 35.3786 33.5269C35.7617 33.3957 35.995 33.1838 36.1102 32.8622C36.1534 32.7425 36.1548 32.7296 36.1548 32.4931C36.1548 32.2609 36.1519 32.2422 36.1159 32.1528C35.9575 31.7534 35.6954 31.5962 34.9667 31.4636C34.094 31.3021 33.865 31.2574 33.7973 31.2314C33.6274 31.1665 33.5525 31.0743 33.5539 30.9286C33.5539 30.7412 33.6763 30.6244 33.9384 30.5581C34.081 30.522 34.562 30.522 34.742 30.5581C34.9149 30.5927 35.0416 30.6431 35.1554 30.721C35.2403 30.7772 35.344 30.8854 35.3599 30.933C35.3642 30.9445 35.4938 30.9185 35.7674 30.8508C35.9878 30.796 36.1779 30.747 36.1879 30.7426C36.2139 30.7282 36.1174 30.5826 35.9964 30.4542C35.73 30.1716 35.3728 30.0029 34.8731 29.9265C34.6988 29.8991 34.1847 29.8876 34.0162 29.9063Z" />
            <path d="M55.748 29.9049C55.1993 29.9655 54.6924 30.1803 54.3928 30.4802C54.2329 30.6403 54.0947 30.8623 54.0184 31.0844C53.883 31.4751 53.8787 32.036 54.0083 32.4282C54.1739 32.9358 54.5771 33.3323 55.1143 33.5212C55.5694 33.6798 56.2189 33.7144 56.7489 33.6091C57.5366 33.4505 58.068 32.9488 58.2164 32.222C58.3302 31.6684 58.238 31.0887 57.9759 30.6951C57.708 30.2928 57.2198 30.026 56.5689 29.9265C56.3874 29.8991 55.9179 29.8876 55.748 29.9049ZM56.2736 30.5393C56.5559 30.5725 56.7734 30.6648 56.9491 30.8292C57.2054 31.0685 57.3379 31.354 57.3566 31.7073C57.3667 31.8976 57.3451 32.049 57.2832 32.2278C57.1406 32.646 56.8799 32.8882 56.458 32.9935C56.2736 33.0396 55.8978 33.0396 55.7134 32.9935C55.4628 32.93 55.2396 32.7974 55.11 32.6315C55.0149 32.5104 54.9213 32.3302 54.8752 32.1759C54.8392 32.0577 54.8349 32.0245 54.8349 31.8082C54.8335 31.602 54.8378 31.5544 54.8666 31.4477C54.9789 31.0382 55.2511 30.7239 55.5996 30.6057C55.8056 30.5365 56.0374 30.5134 56.2736 30.5393Z" />
            <path d="M0 31.1479C0 32.5321 0.00432043 32.5797 0.136814 32.8537C0.341314 33.2762 0.819442 33.5588 1.48191 33.6482C1.68209 33.6741 2.1559 33.6784 2.33303 33.6554C3.04158 33.5602 3.51683 33.279 3.71701 32.8378C3.74005 32.7874 3.77318 32.6922 3.79046 32.6259C3.82214 32.5091 3.82214 32.4889 3.82646 31.2561L3.83222 30.0059H3.39298H2.95374L2.94942 31.1984C2.9451 32.3851 2.9451 32.3923 2.91341 32.4759C2.80972 32.7513 2.54618 32.9041 2.09109 32.9503C1.80306 32.9791 1.50639 32.9359 1.28893 32.8321C1.08875 32.7369 0.974978 32.6042 0.920252 32.4024C0.89433 32.313 0.892889 32.2322 0.892889 31.1566V30.0059H0.446445H0V31.1479Z" />
            <path d="M4.39243 31.7794V33.553H4.81727H5.24211L5.24499 32.3129L5.24931 31.0729L6.33662 32.3086L7.42537 33.5458L7.86749 33.5501L8.30962 33.553V31.7794V30.0059H7.88478H7.45993L7.45705 31.2402L7.45273 32.473L6.37262 31.2402L5.29252 30.0073L4.84319 30.0059H4.39243V31.7794Z" />
            <path d="M8.88568 31.7794V33.553H9.33213H9.77857V31.7794V30.0059H9.33213H8.88568V31.7794Z" />
            <path d="M14.9098 31.2415C14.9156 32.5911 14.9127 32.5493 15.0005 32.7685C15.0653 32.9314 15.1345 33.0352 15.2655 33.1636C15.604 33.4981 16.1282 33.6682 16.8209 33.6711C17.3192 33.6726 17.6763 33.6005 18.009 33.4332C18.3993 33.2386 18.6038 32.9877 18.7032 32.5868C18.7233 32.5046 18.7276 32.3345 18.732 31.2487L18.7377 30.0058H18.297H17.8578V31.1045C17.8578 31.7274 17.852 32.2581 17.8434 32.3273C17.8175 32.5493 17.7066 32.7123 17.5107 32.8103C17.0542 33.041 16.3154 32.9934 16.0058 32.7123C15.928 32.6416 15.8603 32.5291 15.8185 32.3994C15.7941 32.3258 15.7926 32.2307 15.7869 31.1622L15.7825 30.0058H15.3433H14.9041L14.9098 31.2415Z" />
            <path d="M19.2979 31.7794V33.553H21.0045H22.7111V33.2358V32.9185H21.451H20.1908V32.4932V32.0678H21.3573H22.5239V31.7578V31.4478H21.3573H20.1908V31.0441V30.6403H21.4006H22.6103V30.3231V30.0059H20.9541H19.2979V31.7794Z" />
            <path d="M24.6697 31.7795V33.5544L25.8405 33.5487C26.9595 33.5429 27.0171 33.5415 27.1683 33.5126C27.5471 33.4391 27.8135 33.3036 28.0598 33.057C28.2873 32.8292 28.4299 32.561 28.5005 32.2308C28.5451 32.026 28.5552 31.641 28.5235 31.4204C28.4501 30.9273 28.2254 30.5567 27.838 30.2943C27.6652 30.1775 27.4592 30.0982 27.187 30.0463C27.0301 30.016 26.9811 30.016 25.8477 30.0103L24.6697 30.0045V31.7795ZM26.9379 30.6591C27.2403 30.7254 27.4175 30.8538 27.5384 31.0931C27.6738 31.3613 27.7041 31.8689 27.6061 32.2538C27.5399 32.5177 27.4031 32.6965 27.1856 32.8032C26.9523 32.9171 26.8227 32.933 26.0925 32.933H25.5482V31.778V30.6231L26.1934 30.6303C26.6988 30.636 26.8587 30.6418 26.9379 30.6591Z" />
            <path d="M28.9325 31.7794V33.553H30.6391H32.3456V33.2358V32.9185H31.0855H29.8254L29.8283 32.4961L29.8326 32.075L31.0034 32.0707L32.1728 32.0678V31.7578V31.4478H30.9991H29.8254V31.0441V30.6403H31.0351H32.2448V30.3231V30.0059H30.5887H28.9325V31.7794Z" />
            <path d="M36.5797 31.7794V33.553H37.0189H37.4581V31.7794V30.0059H37.0189H36.5797V31.7794Z" />
            <path d="M42.6571 31.7794V33.553H43.0747H43.4924L43.4952 32.3101L43.4996 31.0671L44.5926 32.3101L45.6857 33.553H46.1235H46.5599V31.7794V30.0059H46.1422H45.7246V31.2402C45.7246 31.9236 45.7188 32.4716 45.7116 32.4687C45.7059 32.4658 45.2148 31.9107 44.6214 31.2344L43.5442 30.0059H43.1006H42.6571V31.7794Z" />
            <path d="M48.7489 31.7794V33.553H49.1737H49.5971L49.5914 32.4168C49.587 31.7924 49.5813 31.1825 49.577 31.0614C49.5741 30.9403 49.5755 30.8436 49.5813 30.848C49.587 30.8509 49.8477 31.4608 50.1602 32.2034L50.7276 33.553H51.1337C51.4448 33.553 51.5413 33.5487 51.5471 33.5342C51.596 33.4102 52.6689 30.848 52.6747 30.8436C52.679 30.8393 52.6776 31.4464 52.6718 32.1947L52.6617 33.553H53.0808H53.5013V31.7794V30.0059L52.8648 30.0088L52.2283 30.0131L51.6882 31.3065C51.3901 32.0174 51.1438 32.597 51.1395 32.5941C51.1337 32.5912 50.886 32.0073 50.5865 31.2978L50.0407 30.0059H49.3955H48.7489V31.7794Z" />
            <path d="M58.6715 31.7795V33.5544L59.8351 33.5487C61.0952 33.5444 61.0938 33.5444 61.3833 33.4593C61.6439 33.3843 61.8268 33.2762 62.0371 33.0728C62.3626 32.7571 62.521 32.3807 62.5411 31.8645C62.5656 31.2806 62.4087 30.8235 62.0717 30.489C61.87 30.29 61.6468 30.1617 61.3804 30.0924C61.0852 30.016 61.1068 30.016 59.8495 30.0103L58.6715 30.0045V31.7795ZM60.7957 30.6404C61.065 30.6706 61.2306 30.737 61.3645 30.8653C61.4682 30.9648 61.5244 31.0484 61.5748 31.1811C61.7059 31.5329 61.6886 32.1255 61.5359 32.4528C61.4783 32.5754 61.3242 32.734 61.1989 32.7989C60.9858 32.9114 60.8043 32.933 60.0943 32.933H59.55V31.7795V30.6259H60.1087C60.4155 30.6259 60.7251 30.6332 60.7957 30.6404Z" />
            <path d="M62.9343 31.7794V33.553H64.6409H66.3474V33.2358V32.9185H65.0873H63.8272V32.4932V32.0678H64.9937H66.1602V31.7578V31.4478H64.9937H63.8272V31.0441V30.6403H65.0369H66.2466V30.3231V30.0059H64.5905H62.9343V31.7794Z" />
            <path d="M66.7507 31.7794V33.553H68.1836H69.6166V33.2286V32.9041H68.6301H67.6436V31.455V30.0059H67.1971H66.7507V31.7794Z" />
            <path d="M69.9622 31.7794V33.553H70.4014H70.8407V31.7794V30.0059H70.4014H69.9622V31.7794Z" />
            <path d="M71.4167 31.7794V33.553H71.8344H72.252V32.3115C72.252 31.2012 72.2549 31.0715 72.2736 31.0873C72.2866 31.0974 72.7806 31.6569 73.3725 32.3288L74.4483 33.553H74.8832H75.3195V31.7794V30.0059H74.9019H74.4843V31.2387C74.4843 31.9164 74.4799 32.4716 74.4742 32.4701C74.467 32.4701 73.9773 31.915 73.384 31.2373L72.3053 30.0059H71.8618H71.4167V31.7794Z" />
        </svg>
    )
}

export default logo
