/**
 * external libs
 */
import React from 'react'
/**
 * components
*/
import { Text, H3 } from "../ui";
/**
 * styles
 */
import styles from './worth.module.scss'

type Props = {
    title: string
    description: string
}

const Worth: React.FC<Props> = ({ title, description }) => {
    return (
        <div className={styles.worth}>
            <H3>{title}</H3>
            <Text>{description}</Text>
        </div>
    )
}

export default Worth


